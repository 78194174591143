
import { defineComponent } from "vue";
import variables from "@/router/api";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "get-email-verification-code",
  components: {},
  props: ["hash"],
  created() {
    // Set page title
    document.title = "Email Verification Code | " + process.env.VUE_APP_TITLE;

    // Verify registration verification code
    ApiService.get(variables.VERIFY_EMAIL_ROUTE, this.hash)
      .then((response) => {
        if (response.data.error == undefined) {
          variables.toastAlert(response.data.message, "success");
          this.$router.push({ name: "sign-in" });
        } else {
          variables.toastAlert(response.data.error, "error");
          this.$router.push({ name: "sign-in" });
        }
      })
      .catch(({ response }) => {
        variables.toastAlert(response.data.error, "error");
      });
  },
});
